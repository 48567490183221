// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (...args: any[]): any => {
  // silence is golden
}

export function isStringMatch(str1: string, str2: string) {
  return str1.toUpperCase().replace(/\s/g, '') === str2.toUpperCase().replace(/\s/g, '')
}

export const ERROR_TYPES = {
  GENERIC: 'GENERIC',
  EXISTING_EMAIL: 'EXISTING_EMAIL',
  EXISTING_PHONE: 'EXISTING_PHONE',
  IMAGE_TOO_LARGE: 'IMAGE_TOO_LARGE',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  INVALID_CODE: 'INVALID_CODE',
  INVALID_ZIPCODE: 'INVALID_ZIPCODE',
  INVALID_PHONE: 'INVALID_PHONE'
}

export const ERRORS = {
  [ERROR_TYPES.GENERIC]: 'Something went wrong',
  [ERROR_TYPES.IMAGE_TOO_LARGE]: 'The image that you uploaded is too large. Please upload an image under 10 MB.',
  [ERROR_TYPES.SERVICE_UNAVAILABLE]: 'There was an issue uploading your photo. Please try again in a few minutes.',
  [ERROR_TYPES.INVALID_CODE]: "Oops... that wasn't quite right",
  [ERROR_TYPES.INVALID_ZIPCODE]: 'Invalid Zip Code',
  [ERROR_TYPES.EXISTING_EMAIL]: 'User with the same email is already registered',
  [ERROR_TYPES.EXISTING_PHONE]: 'User with the same mobile phone is already registered',
  [ERROR_TYPES.INVALID_PHONE]: 'Enter a mobile number that can receive sms messages'
}

export const generatePayload = ({ data, whitelist, keypath = null }) => {
  const payload: Record<string, any> = {}
  Object.entries(data).forEach(([key, value]) => {
    if (whitelist.includes(key)) payload[key] = keypath ? value[keypath] : value
  })
  return payload
}

export const PHONE_NUMBER_FORMAT = '(NNN) NNN-NNNN'.split('')
export const NUMBER_CHAR_IDENTIFIER = 'N'

const stripNonDigits = (value: string) => {
  return value.replace(/\D/g, '').slice(0, 10)
}

const parser = (value: string) => {
  // Leave digits only
  return value ? stripNonDigits(value) : ''
}

export const phoneNumberFormatter = (value: string) => {
  const parsedValue = parser(value)

  if (!parsedValue || !parsedValue.length) return ''
  if (parsedValue.length !== 10) return parsedValue

  const parsedValueChars = parsedValue.split('')
  const res = []
  // Format the string based on PHONE_NUMBER_FORMAT rule
  // Loop throught PHONE_NUMBER_FORMAT and replace any N with the corresponding number in value until there are digits to loop through
  PHONE_NUMBER_FORMAT.forEach((val) => {
    if (val === NUMBER_CHAR_IDENTIFIER && parsedValueChars.length) {
      res.push(parsedValueChars.shift())
    } else if (parsedValueChars.length) {
      res.push(val)
    }
  })

  return res.join('')
}
