import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from '@/stories/modals/style'
import ROUTES from '@helpers/routes'

import { InferProps, func, string } from 'prop-types'

const DuplicateAccountDetected = ({
  alertKey,
  setAlertTypeVisibility,
  resetCurrentAction
}: InferProps<typeof DuplicateAccountDetected.propTypes>) => {
  const router = useRouter()
  const theme = useTheme()
  const onClick = () => {
    setAlertTypeVisibility(alertKey, false)
    resetCurrentAction()
  }

  const onLogin = () => {
    router.push(ROUTES.LOGIN)
  }

  const onContactSupport = () => {
    router.push({ pathname: ROUTES.VERIFY, query: { help: true } })
  }

  return (
    <EazeDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>Good to see you again!</Title>
          <Emoji>👋</Emoji>
          <Paragraph>You already have an Eaze account. Would you like to log in or contact support?</Paragraph>
        </Message>

        <ButtonContainer>
          <Button variant="variant-2" backgroundColor={theme.colors.button} onClick={onLogin}>
            LOGIN
          </Button>
          <Button variant="variant-2" onClick={onContactSupport}>
            CONTACT SUPPORT
          </Button>
        </ButtonContainer>
      </Modal>
    </EazeDrawer>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

DuplicateAccountDetected.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  resetCurrentAction: func
}

export default DuplicateAccountDetected
