import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

import { func, string } from 'prop-types'

export default function CartExpired({ alertKey, setAlertTypeVisibility, setExpiredCart }) {
  const requestClose = () => {
    setExpiredCart()
    setAlertTypeVisibility(alertKey, false)
  }

  return (
    <EazeDrawer open onRequestClose={requestClose}>
      <CloseModalIcon onClick={requestClose} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>💤</Emoji>
        <Title>Cart Expired</Title>
        <Paragraph>Uh oh! Looks like your cart got tired of waiting so we emptied it for you.</Paragraph>
        <Button onClick={requestClose}>Keep Shopping</Button>
      </Modal>
    </EazeDrawer>
  )
}

CartExpired.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  setExpiredCart: func
}
