// TODO: split this file into each modal

export const TEST_IDS = {
  COMPONENT: 'ageGateModalComponent',
  VERIFICATION_BUTTON: 'ageGateVerificationButton',
  GOOGLE_LINK: 'ageGateGoogleLink',
  DUPLICATE_ACCOUNT_MODAL: 'duplicateAccountModal',
  VIEW_AREA: 'viewArea',
  VIEW_SAMPLE_MENU: 'viewSampleMenu',
  NO_SERVICE_AREA: 'notInServiceModal',
  CHANGE_ADDRESS: 'changeAddress',
  CASH_ONLY_COMPONENT: 'cashOnlyComponent',
  CASH_ONLY_DISMISS_BUTTON: 'cashOnlyDismissButton'
}
