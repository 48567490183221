/**
 * Checkout actions
 */

import Router from 'next/router'

import { getActiveCart } from '@/redux/cart/selectors'
import { track } from 'analytics'
import api from 'api'
import errorHandler from 'error-handler'
import { EAZE_ORIGIN, STATUS_CODE_DELIVERY_DECREASED, STATUS_CODE_DELIVERY_INCREASED } from 'helpers/constants'
import movableInk from 'helpers/movable-ink'
import { postMessageToReactNative } from 'helpers/native'
import { ACH_PAYMENT_METHOD, ACH_TOKENS, CASH_PAYMENT_METHOD } from 'helpers/payment'
import promisify from 'helpers/pify'
import ROUTES from 'helpers/routes'
import { handleSetAddress, setActiveLocation, setDepotMismatch } from 'redux/action-wrappers/location'
import { deletePromo } from 'redux/action-wrappers/promos'
import { setAddressVisibility } from 'redux/addressModal/actions'
import { getPayloadFromState } from 'redux/analytics/get-payloads'
import { clearCart, createNewCart, handleCartError, isCartError } from 'redux/cart/actions'
import { orderCreateLoaded, orderCreateLoading } from 'redux/loading/actions'
import { requestProducts } from 'redux/menu/actions'
import { lastRequestedOrderAt } from 'redux/order/selectors'
import { setACH, setDriverTip, unsetActivePaymentId } from 'redux/payments/actions'
import {
  getACH,
  getActivePaymentId,
  getDispensaryId,
  getDriverTip,
  getPaymentProviders,
  isCashOnly
} from 'redux/payments/selectors'
import { requestQuote } from 'redux/quote/actions'
import { getLastQuoteTime, getPriceInfo } from 'redux/quote/selectors'

import t from './actionTypes'
import { getDeliveryWindowId, getIsAsapDelivery } from './selectors'

/**
 * This action is passed when the value changes in the the /checkout delivery notes
 * @param {string} notes determining delivery instructions. (i.e. 'Knock on apt 4')
 */
export function setCheckoutNotes(notes: string) {
  return {
    type: t.SET_CHECKOUT_NOTES,
    notes
  }
}

/**
 * This function is used in regards to the New Delivery Instructions Split Experiment
 * in order for us to track what options/text a user added when they pressed 'Place Order' in checkout
 */
export function setDeliveryInstructionNotes(deliveryNotes: string) {
  return {
    type: t.SET_DELIVERY_NOTES,
    deliveryNotes
  }
}

export function setCheckoutError(error: string) {
  return {
    type: t.SET_CHECKOUT_ERROR,
    payload: error
  }
}

export function setCheckoutMessage(message: string, messageStatusCode: number) {
  return {
    type: t.SET_CHECKOUT_MESSAGE,
    payload: {
      message,
      messageStatusCode
    }
  }
}

export function clearCheckoutMessage() {
  return {
    type: t.CLEAR_CHECKOUT_MESSAGE
  }
}

export function clearCheckoutError() {
  return {
    type: t.CLEAR_CHECKOUT_ERROR
  }
}

const apiCheckout = promisify(api.checkout)

type OrderPayload = {
  notes: string
  cartId: number
  paymentMethod: number
  lastQuoteTime: string
  origin: string
  driverTip: number
  promoCode?: string
  ach?: {
    id: string
    accessToken: string
  }
  providerId?: number
  dispensaryId?: number
  asap: boolean
  deliveryWindowId?: number
}
// this action is called when we want to write an order
export function postOrder() {
  return async (dispatch, getState) => {
    const state = getState()
    const { checkout, promo } = state
    const cart = getActiveCart(state)
    const { notes } = checkout
    const dispensaryId = getDispensaryId(state)
    const priceInfo = getPriceInfo(state)
    const lastQuoteTime = getLastQuoteTime(state)
    const lastRequestedOrderAtData = lastRequestedOrderAt(state)
    const hasOnlyCashOptionAvailable = isCashOnly(state)
    const ach = getACH(state)
    const paymentProviders = getPaymentProviders(state)
    const orderRevenue = (
      priceInfo.subtotal -
      priceInfo.promoCredit -
      priceInfo.inviteCredit -
      priceInfo.veteranDiscount
    ).toFixed(2)
    let driverTip = getDriverTip(state)
    // Scheduled Delivery
    const isAsapDelivery = getIsAsapDelivery(state)
    const deliveryWindowId = getDeliveryWindowId(state)

    let activePaymentId = getActivePaymentId(state)

    // if the charge amount is zero, but total price is > 0, we can assume that the user has enough credits to cover the total cost
    const allCreditPurchase = priceInfo.chargeAmount === 0 && priceInfo.totalPrice > 0

    if (allCreditPurchase) {
      activePaymentId = CASH_PAYMENT_METHOD
    }
    // if address doesn't support credit cards, lets fall back to cash.
    if (hasOnlyCashOptionAvailable) {
      activePaymentId = CASH_PAYMENT_METHOD
    }

    if (activePaymentId === CASH_PAYMENT_METHOD) {
      setDriverTip(0)
      driverTip = 0
    }

    dispatch(cleanCheckout())
    dispatch(orderCreateLoading())

    const payload: OrderPayload = {
      notes: notes,
      cartId: cart.id,
      paymentMethod: activePaymentId,
      lastQuoteTime,
      origin: EAZE_ORIGIN,
      driverTip,
      asap: isAsapDelivery
    }

    // Scheduled Delivery
    if (!isAsapDelivery) {
      payload.deliveryWindowId = deliveryWindowId
    }

    if (promo.code !== '') {
      payload.promoCode = promo.code
    }

    if (activePaymentId === ACH_PAYMENT_METHOD) {
      const providerId =
        paymentProviders && paymentProviders[ACH_PAYMENT_METHOD] ? paymentProviders[ACH_PAYMENT_METHOD].id : null
      if (!ach || (!ach.id && !ach.accessToken)) {
        errorHandler(new Error('Error: invalid ACH at checkout'))
        dispatch(setCheckoutError('Something went wrong. Please reselect a payment method.'))
        return
      }

      payload.ach = ach
      payload.providerId = providerId
      payload.dispensaryId = dispensaryId
    }

    const productsList = cart.productsList.map(({ catalogItemId, name, price, quantity }) => {
      return {
        catalogItemId,
        name,
        price,
        quantity
      }
    })

    const movableInkInfo = {
      products: productsList,
      chargeAmount: priceInfo.chargeAmount,
      cartId: cart.id
    }

    // Send info to Movable Ink
    movableInk(movableInkInfo)

    return apiCheckout(payload)
      .then((data) => {
        dispatch(orderCreateLoaded())
        dispatch(deletePromo())
        const checkoutSuccessPayload = {
          ...getPayloadFromState(getState()),
          orderId: data.id,
          orderRevenue, // order revenue info for Tune
          isAsapDelivery: !data.deliveryWindowId,
          deliveryWindowId: data.deliveryWindowId || 'asap'
        }
        const postMsgPayload = {
          orderId: data.id,
          chargeAmount: checkoutSuccessPayload.chargeAmount
        }
        postMessageToReactNative(postMsgPayload)

        track('Checkout.Success', checkoutSuccessPayload)
        // do we still need this event?
        track('Checkout.Success.Optimizely') // We need a specific Optimizely event because the payload is too large otherwise
        // if lastRequestedOrderAtData is null it means this is a user's first order
        if (lastRequestedOrderAtData === null) {
          track('Checkout.Success.First', postMsgPayload)
        }
        dispatch(createNewCart(true))
        // Remove any achTokens
        window.sessionStorage.removeItem(ACH_TOKENS)

        Router.push(`/orders/${data.id}`)
      })
      .catch((err) => {
        dispatch(orderCreateLoaded())
        const { statusCode, message } = err
        const isDeliveryIncreaseStatus = statusCode === STATUS_CODE_DELIVERY_INCREASED
        const isDeliveryDecreaseStatus = statusCode === STATUS_CODE_DELIVERY_DECREASED

        if (statusCode === 409) {
          // conflict status means backend already has an order and somehow a user tried to order again
          // the message contains the original order id so let's kick the user to the order status page
          return Router.push(`/orders/${message}`)
        }

        if (isDeliveryDecreaseStatus || isDeliveryIncreaseStatus) {
          dispatch(requestQuote())
          dispatch(setCheckoutMessage(message, statusCode))
        }
        // Sometimes the 'Cart is not Active (it is ordered|deleted|expired)' error comes through this way
        // usually due to having multiple tabs open, let's handle it as a cart error
        if (isCartError(err)) {
          handleCartError(err, dispatch)
          postOrder()
        } else if (!isDeliveryDecreaseStatus && !isDeliveryIncreaseStatus) {
          // only set an error if it's not a delivery increase/decrease status
          errorHandler(new Error(err.message)) // We want to always log checkout errors as errors in Sentry
          dispatch(setCheckoutError(message))

          if (activePaymentId === ACH_PAYMENT_METHOD && message.match(/fix_flow_required/)) {
            dispatch(clearCheckoutError())
            dispatch(toggleACHFixDrawer())
          } else if (activePaymentId === ACH_PAYMENT_METHOD && message.match(/Payment failed/)) {
            dispatch(toggleACHDeclineDrawer())
          } else {
            dispatch(setCheckoutMessage(message, statusCode))
          }
        }

        track('Checkout.Error', getPayloadFromState(getState()))
      })
  }
}

export function viewCheckout() {
  return (dispatch, getState) => {
    track('Checkout.View', getPayloadFromState(getState()))

    dispatch({
      type: t.VIEW_CHECKOUT
    })
  }
}

// function to initialize checkout (currently loading state, error, message)
export function cleanCheckout() {
  return function (dispatch) {
    dispatch(clearCheckoutMessage())
    dispatch(orderCreateLoaded())
    dispatch(clearCheckoutError())
  }
}

// abortCheckoutNewDepot happens if the user confirms they want to switch depots / regions.
// So we set potentialAddress as the users .activeLocation, and go back to menu while fetching a new product list
export function abortCheckoutNewDepot() {
  return (dispatch, getState) => {
    const state = getState()
    const { potentialAddress } = state.location

    // if the new location is out of service area, don't set it as potentialAddress
    if (state.location.potentialAddress.depot) {
      dispatch(setActiveLocation(potentialAddress))
      dispatch(handleSetAddress(potentialAddress.id))
    }
    dispatch(unsetActivePaymentId())
    dispatch(setACH(null))
    dispatch(setAddressVisibility(false))
    dispatch(requestProducts())
    dispatch(clearCart())

    // dismiss this value so it can be reset
    dispatch(setDepotMismatch(false, true))

    // clear errors since we're changing delivery location
    dispatch(clearCheckoutError())

    // we want to create a new cart with the new location
    // so we pass isEmpty true to throw away the old cart
    dispatch(deletePromo())
    dispatch(createNewCart(true))
    Router.push(ROUTES.MENU)
  }
}

export function resetCheckout() {
  return {
    type: t.RESET_CHECKOUT
  }
}

export function toggleACHDeclineDrawer() {
  return {
    type: t.TOGGLE_CARD_DECLINE_DRAWER
  }
}

export function toggleACHFixDrawer() {
  return {
    type: t.TOGGLE_ACH_FIX_DRAWER
  }
}

export function setAsapDelivery() {
  return {
    type: t.SET_ASAP_DELIVERY
  }
}

export function setDeliveryWindow(deliveryWindowId: number) {
  return {
    type: t.SET_DELIVERY_WINDOW,
    deliveryWindowId
  }
}
