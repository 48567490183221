import { WithRouterProps } from 'next/dist/client/with-router'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function AppOutdated({ router }: WithRouterProps) {
  return (
    <EazeDrawer open allowClose={false}>
      <Modal>
        <Emoji>⏰</Emoji>
        <Title>Eaze has been updated!</Title>
        <Paragraph>A new version of Eaze is available.</Paragraph>
        <Button variant="variant-9" onClick={() => router.reload()}>
          Update Now
        </Button>
      </Modal>
    </EazeDrawer>
  )
}
