import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'
import ROUTES from '@helpers/routes'

import { func, object, string } from 'prop-types'

const StoreClosed = ({ closedMessage, resetCurrentAction, currentAction, router }) => {
  const onClick = () => {
    currentAction ? resetCurrentAction() : router.push(ROUTES.MENU)
  }

  return (
    <EazeDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>😴</Emoji>
        <Title>{`We're currently closed.`}</Title>
        <Paragraph>{closedMessage}</Paragraph>
        <Button variant={`variant-14`} onClick={onClick}>
          Continue browsing
        </Button>
      </Modal>
    </EazeDrawer>
  )
}

export default StoreClosed

StoreClosed.propTypes = {
  closedMessage: string,
  currentAction: string,
  resetCurrentAction: func,
  router: object
}
