import React, { PureComponent } from 'react'

import Drawer from 'microcomponents/drawer'

import { node } from 'prop-types'

export default class AlertDrawer extends PureComponent {
  static propTypes = {
    children: node
  }

  render() {
    return <Drawer {...this.props}>{this.props.children}</Drawer>
  }
}
