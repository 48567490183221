/**
 * Container to be used wherever we want to show a modal, either for errors
 * or information
 */

import { withRouter } from 'next/router'
import { connect } from 'react-redux'

import Modal from '@/stories/modals'
import { setDepotMismatch } from 'redux/action-wrappers/location'
import { toggleAddress } from 'redux/addressModal/actions'
import { setAlertTypeVisibility, resetCurrentAction } from 'redux/alert/actions'
import { getClosedMessage, getModalKey } from 'redux/alert/selectors'
import { setDismissedAnnouncements } from 'redux/announcements/actions'
import { getNewestAnnouncement } from 'redux/announcements/selectors'
import { logout } from 'redux/app/actions'
import { cartSizeMismatch, setExpiredCart } from 'redux/cart/actions'
import { abortCheckoutNewDepot } from 'redux/checkout/actions'
import { setCookie } from 'redux/cookies/actions'
import { clearPotentialAddress } from 'redux/location/actions'
import { getActiveDepot, getActiveLocation, getDepotMinimumOrder } from 'redux/location/selectors'
import { getCardChargeDescriptor } from 'redux/order/selectors'
import { acceptCollectiveAgreement, setChangePassword } from 'redux/profile/actions'
import { getDeclinedReasons, getDeclinedComment } from 'redux/profile/selectors'
import { toggleRemovedPromo } from 'redux/promo/actions'
import { getPromoCode } from 'redux/promo/selectors'
import { getVerificationProcessErrors } from 'redux/verification/selectors'

const mapStateToProps = (state, props) => {
  const activeDepot = getActiveDepot(state)
  const activeLocation = getActiveLocation(state)
  const {
    alert: alertStates,
    profile: {
      patient: { contentUrl: recUrl }
    }
  } = state
  const { router } = props

  return {
    activeDepot,
    activeLocation,
    alertKey: getModalKey(state, props),
    cartMinimum: getDepotMinimumOrder(state),
    chargeDescriptor: getCardChargeDescriptor(state),
    closedMessage: getClosedMessage(state),
    currentAction: alertStates.currentAction,
    declinedComment: getDeclinedComment(state),
    declinedReasons: getDeclinedReasons(state),
    newestAnnouncement: getNewestAnnouncement(state),
    promoCode: getPromoCode(state),
    recUrl,
    router,
    verificationProcessErrors: getVerificationProcessErrors(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    abortCheckoutNewDepot: () => dispatch(abortCheckoutNewDepot()),
    acceptCollectiveAgreement: () => dispatch(acceptCollectiveAgreement()),
    clearPotentialAddress: () => dispatch(clearPotentialAddress()),
    logout: (redirectUrl) => dispatch(logout(redirectUrl)),
    resetCartSizeMismatch: () => dispatch(cartSizeMismatch(false)),
    resetCurrentAction: () => dispatch(resetCurrentAction()),
    resetDepotMismatch: () => dispatch(setDepotMismatch(false)),
    setAlertTypeVisibility: (key, setToShow) => dispatch(setAlertTypeVisibility(key, setToShow)),
    setChangePassword: (changePassword) => dispatch(setChangePassword(changePassword)),
    setCookie: (cookie) => dispatch(setCookie(cookie)),
    setDismissedAnnouncements: (id) => dispatch(setDismissedAnnouncements(id)),
    setExpiredCart: () => dispatch(setExpiredCart(false)),
    toggleAddress: () => dispatch(toggleAddress()),
    toggleRemovedPromo: () => dispatch(toggleRemovedPromo())
  }
}

const reduxContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal))

export default reduxContainer
