/**
 * Modal that shows up on /menu after a successful password reset / change.
 */

import { PureComponent } from 'react'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

import { InferProps, func } from 'prop-types'

export default class ChangedPassword extends PureComponent<InferProps<typeof ChangedPassword.propTypes>> {
  static propTypes = {
    setChangePassword: func
  }

  handleCloseRequest = () => {
    this.props.setChangePassword(false)
  }

  render() {
    return (
      <EazeDrawer open onRequestClose={this.handleCloseRequest}>
        <CloseModalIcon onClick={this.handleCloseRequest} src="/static/icons/close.svg" />
        <Modal>
          <Emoji>🔑</Emoji>
          <Title>Success!</Title>
          <Paragraph>Your password has successfully been changed.</Paragraph>
          <Button onClick={this.handleCloseRequest}>Start shopping</Button>
        </Modal>
      </EazeDrawer>
    )
  }
}
