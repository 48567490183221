/**
 * Upload error modal component used for state ID and MMID upload
 * Shows a loading spinner and a message
 */
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

import { func, string } from 'prop-types'

const UploadError = ({ alertKey, setAlertTypeVisibility, verificationProcessErrors }) => {
  const onClick = () => {
    setAlertTypeVisibility(alertKey, false)
  }

  return (
    <EazeDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>😳</Emoji>
        <Title>Oops!</Title>
        <Paragraph>{verificationProcessErrors}</Paragraph>

        <Button onClick={onClick}>OK</Button>
      </Modal>
    </EazeDrawer>
  )
}

UploadError.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  verificationProcessErrors: string
}

export default UploadError
