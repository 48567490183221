import { PureComponent } from 'react'

import Link from 'next/link'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

import { InferProps, func, string } from 'prop-types'

export default class CollectiveAgreement extends PureComponent<InferProps<typeof CollectiveAgreement.propTypes>> {
  static propTypes = {
    acceptCollectiveAgreement: func,
    alertKey: string,
    setAlertTypeVisibility: func
  }

  handleCloseRequest = () => {
    const { alertKey, setAlertTypeVisibility, acceptCollectiveAgreement } = this.props
    setAlertTypeVisibility(alertKey, false)
    acceptCollectiveAgreement()
  }

  render() {
    return (
      <EazeDrawer open allowClose={false} onRequestClose={this.handleCloseRequest}>
        <Modal>
          <Emoji>✍️</Emoji>
          <Title>{`We've updated our agreements!`}</Title>
          <Paragraph style={{ marginBottom: '1rem' }}>
            In order to continue using Eaze, please review our updated terms.
          </Paragraph>
          <Paragraph>
            {`By clicking "I Agree," I accept Eaze's `}
            <Link href="/terms-of-service">
              <a rel="noopener noreferrer" target="_blank">
                Terms of Service
              </a>
            </Link>{' '}
            and{' '}
            <Link href="/privacy-policy">
              <a rel="noopener noreferrer" target="_blank">
                Privacy Policy
              </a>
            </Link>
            .
          </Paragraph>
          <Button onClick={this.handleCloseRequest}>I agree</Button>
        </Modal>
      </EazeDrawer>
    )
  }
}
