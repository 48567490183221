export const CHECKBOX = 'checkbox'

export const DEFAULT = 'DEFAULT'
export const AGREEMENT = 'AGREEMENT'

export const PHONE_NUMBER_FORMAT = '(NNN) NNN-NNNN'.split('')
export const NUMBER_CHAR_IDENTIFIER = 'N'

export function stripNonDigits(value) {
  return value.replace(/\D/g, '').substr(0, 10)
}

export const parser = (value) => {
  return value ? stripNonDigits(value) : ''
}

export const formatter = (value) => {
  let parsedValue = parser(value)

  if (!parsedValue || !parsedValue.length) return ''
  if (parsedValue.length !== 10) return parsedValue

  parsedValue = parsedValue.split('')
  const res = []
  // Format the string based on PHONE_NUMBER_FORMAT rule
  // Loop through PHONE_NUMBER_FORMAT and replace any N with the corresponding number in value until there are digits to loop through
  PHONE_NUMBER_FORMAT.forEach((val) => {
    if (val === NUMBER_CHAR_IDENTIFIER && parsedValue.length) {
      res.push(parsedValue.shift())
    } else if (parsedValue.length) {
      res.push(val)
    }
  })
  return res.join('')
}

export default {
  stripNonDigits,
  formatter,
  parser,
  CHECKBOX,
  DEFAULT,
  AGREEMENT,
  PHONE_NUMBER_FORMAT,
  NUMBER_CHAR_IDENTIFIER
}
