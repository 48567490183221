import { PureComponent } from 'react'

import styled from '@emotion/styled'
import Image from 'next/image'
import Link from 'next/link'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import ROUTES from '@helpers/routes'

import { InferProps, func, string } from 'prop-types'

import { TEST_IDS } from '../test/constants'

export default class LocationOutOfService extends PureComponent<InferProps<typeof LocationOutOfService.propTypes>> {
  static propTypes = {
    alertKey: string,
    clearPotentialAddress: func,
    resetCurrentAction: func,
    setAlertTypeVisibility: func,
    toggleAddress: func
  }

  handleClosing = () => {
    const { alertKey, clearPotentialAddress, setAlertTypeVisibility, resetCurrentAction } = this.props

    setAlertTypeVisibility(alertKey, false)
    clearPotentialAddress()
    resetCurrentAction()
  }

  handleChangingAddress = () => {
    const { alertKey, setAlertTypeVisibility, toggleAddress, resetCurrentAction } = this.props

    setAlertTypeVisibility(alertKey, false)
    toggleAddress()
    resetCurrentAction()
  }

  render() {
    return (
      <EazeDrawer open onRequestClose={this.handleClosing}>
        <Modal>
          <CloseModalIcon onClick={this.handleClosing} src="/static/icons/close.svg" />
          <Content data-e2eid={TEST_IDS.NO_SERVICE_AREA}>
            <ImageContainer>
              <Image src="/static/location.png" width={103} height={103} />
            </ImageContainer>
            <DrawerTitle>Area Not In Service</DrawerTitle>
            <Paragraph>
              Eaze is not currently available in your area. You can{' '}
              <Button variant="variant-10" onClick={this.handleChangingAddress}>
                try a different address
              </Button>{' '}
              or{' '}
              <Link href={ROUTES.DELIVERYAREA}>
                <a>view our current service area</a>
              </Link>
            </Paragraph>
            <Link href={ROUTES.DELIVERYAREA}>
              <a>
                <Button data-e2eid={TEST_IDS.VIEW_AREA}>View Service Area</Button>
              </a>
            </Link>
            <LinkContainer>
              <Button variant="variant-10" data-e2eid={TEST_IDS.CHANGE_ADDRESS} onClick={this.handleChangingAddress}>
                Change My Address
              </Button>
            </LinkContainer>
          </Content>
        </Modal>
      </EazeDrawer>
    )
  }
}

const Content = styled.div`
  text-align: center;
`

const ImageContainer = styled.div`
  margin-left: 0.33rem;
`

const DrawerTitle = styled(Title)`
  margin-bottom: 2rem;
`
