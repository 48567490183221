/**
 * this file is used for firing events to Movable Ink
 */

import window from 'global/window'

// Pass product w/ specific info like name, catalogId, quantity, etc.
export default function movableInk({ products, chargeAmount, cartId }) {
  const mitr = window.mitr || function () {}

  // Add a product for each product in the cart
  for (let i = 0; i < products.length; i++) {
    mitr('addProduct', {
      sku: products[i].catalogItemId,
      name: products[i].name,
      price: products[i].price,
      quantity: products[i].quantity
    })
  }

  mitr('send', 'conversion', {
    revenue: chargeAmount.toFixed(2),
    identifier: cartId
  })
}
