import { PureComponent } from 'react'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import { track } from '@helpers/analytics'

import { InferProps, func, string } from 'prop-types'

export default class DepotMismatch extends PureComponent<InferProps<typeof DepotMismatch.propTypes>> {
  static propTypes = {
    abortCheckoutNewDepot: func,
    alertKey: string,
    promoCode: string,
    resetDepotMismatch: func,
    setAlertTypeVisibility: func
  }

  handleCloseRequest = () => {
    const { alertKey, resetDepotMismatch, setAlertTypeVisibility } = this.props
    track('Modal.DeliveryZoneChange.Click', { value: 'dontChangeAddress' })
    resetDepotMismatch()
    setAlertTypeVisibility(alertKey, false)
  }

  componentDidMount() {
    track('Modal.DeliveryZoneChange.View')
  }

  handleChangeAddress = () => {
    track('Modal.DeliveryZoneChange.Click', { value: 'changeAddress' })
    this.props.abortCheckoutNewDepot()
  }

  render() {
    const { promoCode } = this.props
    return (
      <EazeDrawer open onRequestClose={this.handleCloseRequest}>
        <Modal>
          <Emoji>⚠️</Emoji>
          <Title>{`You're changing delivery zones.`}</Title>
          <Paragraph>
            {`Changing your address will clear your cart${promoCode && ' and promo code'}. Do you want to proceed?`}
          </Paragraph>
          <Button variant={'variant-1'} onClick={this.handleChangeAddress}>
            {'Yes, change my address'}
          </Button>
          <LinkContainer>
            <LinkButton onClick={this.handleCloseRequest}>{`Don't change my address`}</LinkButton>
          </LinkContainer>
        </Modal>
      </EazeDrawer>
    )
  }
}
