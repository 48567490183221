import { FC, Fragment, KeyboardEvent, useRef } from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { breakpoint, lightTheme } from '@/theme'
import { ErrorIcon } from '@microcomponents/icons/new'

// Styled components

const StyledCodeInput = styled.input`
  border: none;
  border-bottom: 1px solid ${lightTheme.colors.grey};
  cursor: pointer;
  display: inline-flex;
  font-family: ${lightTheme.typography.font.sim};
  font-size: ${lightTheme.typography.size.desktop.title2}px;
  font-weight: ${lightTheme.typography.weight.bold};
  line-height: 1.75;
  outline: none;
  width: ${lightTheme.spacing.larger}px;
  padding-left: calc(${lightTheme.spacing.larger}px / 4);

  &:focus {
    border-color: ${lightTheme.colors.border};
  }

  @media (max-width: ${breakpoint.max.md}px) {
    border-radius: 0;
    padding: 0;
    text-align: center;
  }
`

const Container = styled.div`
  margin-bottom: 40px;
`

const InputWrapper = styled.div<{ codeLength: number }>`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ theme, codeLength }) => theme.spacing.larger * codeLength + theme.spacing.medium * (codeLength - 1)}px;
`

const ErrorMessage = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.danger};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.typography.font.simMono};
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 14px;
  align-items: center;
`

// Main exported component

type CodeInputProps = {
  codeLength?: number
  hasWrongCode: boolean
  onChange: (value: string) => void
  errorMessage: string
}

export const CodeInput: FC<CodeInputProps> = ({
  codeLength = 4,
  hasWrongCode = false,
  onChange,
  errorMessage = ''
}) => {
  const theme = useTheme()

  const inputRefs = useRef<HTMLInputElement[]>([])

  const hiddenInputRef = useRef<HTMLInputElement>(null)

  const indices = Array.from({ length: codeLength }, (_, index) => index)

  const getFullCode = () => {
    return inputRefs.current.map((input) => input.value).join('')
  }

  const handleKeyDown = (e: unknown, index: number) => {
    const event = e as KeyboardEvent<HTMLInputElement>
    const target = event.target as HTMLInputElement

    if (event.keyCode === 8 && target.value === '') {
      event.preventDefault()
      const previousIndex = Math.max(0, index - 1)
      inputRefs.current[previousIndex]?.focus()
      inputRefs.current[previousIndex].value = target.value
    }
    const fullCode = getFullCode()
    onChange(fullCode)
  }

  // Handle input
  const handleInput = (e, index: number) => {
    const input = e.target

    const inputNumbers = input.value.replace(/\D/g, '')

    if (inputNumbers.length === 0) {
      e.stopPropagation()
      inputRefs.current[index].value = ''
      return false
    }

    const [first, ...rest] = inputNumbers
    input.value = first ?? ''

    const isLastInputBox = index === inputRefs.current.length - 1
    const didInsertContent = first !== undefined

    if (didInsertContent && !isLastInputBox) {
      inputRefs.current[index + 1]?.focus()
      inputRefs.current[index + 1].value = rest.join('')
    }

    const fullCode = getFullCode()

    hiddenInputRef.current.value = fullCode
    onChange(fullCode)
  }

  const renderInputs = (index: number) => {
    return (
      <StyledCodeInput
        id="code"
        name="code"
        type="tel"
        onChange={(e) => handleInput(e, index)}
        onKeyDown={(e) => handleKeyDown(e, index)}
        key={index}
        maxLength={4}
        autoComplete="one-time-code"
        required
        ref={(node) => {
          inputRefs.current[index] = node
        }}
      />
    )
  }

  return (
    <Fragment>
      {/* Hidden input contains value from all code inputs combined. Use this value when submitting the form. */}
      <input type="hidden" name="code" ref={hiddenInputRef} />
      <Container>
        <InputWrapper codeLength={codeLength}>{indices.map((index) => renderInputs(index))}</InputWrapper>
        {hasWrongCode && (
          <ErrorContainer>
            <ErrorIcon color={theme.colors.danger} />
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </ErrorContainer>
        )}
      </Container>
    </Fragment>
  )
}

export default CodeInput
